import React, { useState } from "react";
import "./ProductCard.scss";
import Modal from "react-bootstrap/Modal";

import plus from "../../Assets/Images/plus.svg";

const ProductModal = (props) => {
  const handleClose = () => {
    props.setShow(false);
  };
  return (
    <>
      <Modal className="" show={props.show} onHide={handleClose}>
        <Modal.Body className="product-modal">
          <img className="modal-img" src={props.product.image} alt="" />
          <div className="text-content">
            <h1 className="name">
              {props.product.name
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
            </h1>
            <p className="desc">{props.product.description}</p>
            {props.product.price ? (
              <h2 className="price">{`${props.product.price} MRU`}</h2>
            ) : (
              `0 MRU`
            )}
          </div>
          {props.product.needsPrescription ? (
            <p className="pres desc">
              Nécessite une ordonnance <br /> يحتاج روشتة
            </p>
          ) : (
            <button
              onClick={() => {
                props.handleAddToCart();
                props.setShow(false);
              }}
              className="add"
            >
              {/* <img src={plus} alt="" /> */}
              Add
            </button>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

const ProductCard = (props) => {
  const [showAddedToCart, setShowAddedToCart] = useState(false);
  const [show, setShow] = useState(false);
  let price;
  props.product.price ? (price = props.product.price) : (price = 0);

  const name = props.product.name
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  const handleShowModal = () => {
    setShow(true);
  };

  const handleAddToCart = () => {
    props.addToCart(props.product);
    setShowAddedToCart(true);
    setTimeout(() => {
      setShowAddedToCart(false);
    }, 2000);
  };

  return (
    <div className="product-card">
      <ProductModal
        product={props.product}
        show={show}
        handleAddToCart={handleAddToCart}
        setShow={setShow}
      />
      <button onClick={handleShowModal} className="product-card-layover">
        <div className="right">
          <img className="image" src={props.product.image} alt="" />
        </div>
        <div className="text-content">
          <h1 className="name">{name}</h1>
          <p className="desc">{props.product.description}</p>

          <h2 className="price">{`${price} MRU`}</h2>
        </div>
      </button>
      {props.product.needsPrescription ? (
        <p className="pres desc">
          Nécessite une ordonnance <br /> يحتاج روشتة
        </p>
      ) : (
        <button onClick={handleAddToCart} className="add">
          <img src={plus} alt="" />
          Ajouter
        </button>
      )}

      {showAddedToCart && <div className="cart-popup">Ajouté au panier</div>}
    </div>
  );
};

export default ProductCard;
