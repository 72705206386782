import React, { useState, useEffect, Suspense } from "react";
import "./Products.scss";
import back from "../../Assets/Images/back.svg";
import { useLocation } from "react-router-dom";
import axios from "axios";
import ProductCardList from "../../Components/ProductCardList/ProductCardList";
import pot from "../../Assets/Images/pot.svg";
import { Link } from "react-router-dom";

import { backend } from "../../Context/Context";

import Cart from "../Cart/Cart";

const Products = (props) => {
  const [cartItems, setCartItems] = useState([]);
  const [showCart, setShowCart] = useState(false);
  const [pharmacy, setPharmacy] = useState("");
  const [products, setProducts] = useState([]);
  const location = useLocation();
  const pharmacyId = location.pathname.split("/")[2];
  const [temp, setTemp] = useState(false);
  const [loading, setLoading] = useState(true);
  const cartItemCount =
    cartItems.length > 0
      ? cartItems.reduce((acc, item) => acc + item.quantity, 0)
      : 0;
  const [searchQuery, setSearchQuery] = useState("");
  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  function addToCart(item) {
    const itemIndex = cartItems.findIndex(
      (cartItem) => cartItem._id === item._id
    );

    if (itemIndex === -1) {
      // If the item doesn't exist in the cart, add it with a quantity of 1
      setCartItems([...cartItems, { ...item, quantity: 1 }]);
    } else {
      // If the item already exists in the cart, increase its quantity by 1
      const updatedCartItems = [...cartItems];
      updatedCartItems[itemIndex] = {
        ...updatedCartItems[itemIndex],
        quantity: updatedCartItems[itemIndex].quantity + 1,
      };
      setCartItems(updatedCartItems);
    }
  }

  function removeFromCart(item) {
    if (!item || !item._id) {
      // If the item is undefined or doesn't have an _id property, do nothing
      return;
    }

    const itemIndex = cartItems.findIndex(
      (cartItem) => cartItem._id === item._id
    );

    if (itemIndex === -1) {
      // If the item doesn't exist in the cart, do nothing
      return;
    } else if (cartItems[itemIndex].quantity === 1) {
      // If the item exists in the cart and its quantity is 1, remove it from the cart
      const updatedCartItems = cartItems.filter(
        (cartItem) => cartItem._id !== item._id
      );
      setCartItems(updatedCartItems);
    } else {
      // If the item exists in the cart and its quantity is greater than 1, decrease its quantity by 1
      const updatedCartItems = [...cartItems];
      updatedCartItems[itemIndex] = {
        ...updatedCartItems[itemIndex],
        quantity: updatedCartItems[itemIndex].quantity - 1,
      };
      setCartItems(updatedCartItems);
    }
  }

  function openCart() {
    setShowCart(true);
  }

  function closeCart() {
    setShowCart(false);
  }

  const getPharmacy = async () => {
    console.log(pharmacyId);
    await axios.get(`${backend}api/account/${pharmacyId}`).then((res) => {
      setPharmacy(res.data.pharmacy);
      // console.log(pharmacy);
    });
    setTemp(true);
  };

  const getProducts = async () => {
    await axios
      .get(`${backend}api/product/pharmacy-products/${pharmacyId}`)
      .then((res) => {
        setProducts(res.data.products);
        console.log(products);
        setLoading(false);
      });
  };

  const fetch = () => {
    getPharmacy();
    getProducts();
  };

  useEffect(() => {
    fetch();
    // console.log(pharmacy);
  }, [temp]);

  return (
    <Suspense fallback={<div class="lds-hourglass"></div>}>
      <div className="products-page">
        {/* _________________ */}
        {products && showCart && (
          <div className="modal">
            <div className="modal-content">
              <span className="cart-close-btn" onClick={closeCart}>
                &times;
              </span>
              <Cart
                // onAdd={onAdd()}
                // onRemove={onRemove()}
                removeFromCart={removeFromCart}
                cartItems={cartItems}
                pharmacyId={pharmacyId}
                pharmacyName={pharmacy.name}
                pharmacyLocation={pharmacy.city}
              />
            </div>
          </div>
        )}

        {/* _________________ */}
        {loading && (
          <>
            <br />
            <br />
            <br />
            <div class="lds-hourglass"></div>
          </>
        )}
        {pharmacy && (
          <>
            <div
              style={{
                backgroundImage: `url(${pharmacy.headerPic})`,
              }}
              className="pharmacy-header"
            >
              <a
                style={{
                  display: "flex",
                  paddingTop: "24px",
                  paddingLeft: "16px",
                }}
                href="/"
              >
                <img src={back} alt="" />
              </a>
            </div>

            <div className="pharmacy-header-card">
              <div className="card-body">
                <img className="card-image" src={pharmacy.profilePic} alt="" />
                <div className="texts">
                  <h1 className="card-title">{pharmacy.name}</h1>
                  <p className="card-description">{pharmacy.description}</p>
                </div>
              </div>
              <p className="card-pres">Vous avez une ordonnance? معاك روشتة؟</p>
              <button className="-btn">
                <Link
                  to={{
                    pathname: "/checkout",
                  }}
                  state={{
                    // cartItems,
                    // total,
                    pharmacyId: pharmacyId,
                    pharmacyName: pharmacy.name,
                    prescription: true,
                  }}
                  className="pres-btn"
                >
                  {/* <img src={pot} alt="" /> */}
                  Cliquez ici
                </Link>
              </button>
            </div>
            <div className="search-bar content__padding">
              <input
                type="text"
                placeholder="Search products..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            {loading && (
              <>
                <br />
                <br />
                <br />
                <div class="lds-hourglass"></div>
              </>
            )}
            <div className="pres">
              <Link
                to={{
                  pathname: "/checkout",
                }}
                state={{
                  // cartItems,
                  // total,
                  pharmacyId: pharmacyId,
                  pharmacyName: pharmacy.name,
                  prescription: true,
                }}
                className="pres-btn"
              >
                {/* <img src={pot} alt="" /> */}
                cliquez ici si vous avez une ordonnance
              </Link>
            </div>
            {products && (
              <ProductCardList list={filteredProducts} addToCart={addToCart} />
            )}
            <div className="content__padding">
              <button onClick={() => setShowCart(true)} className="cart-btn">
                <img src={pot} alt="" />
                السلة - Chariot ({cartItemCount})
              </button>
            </div>
          </>
        )}
      </div>
    </Suspense>
  );
};

export default Products;
